<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta content="width=device-width, initial-scale=1.0" name="viewport">

    <meta content="" name="description">
    <meta content="" name="keywords">

    <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap"
        rel="stylesheet">

</head>

<body>
    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top d-flex align-items-center">
        <div class="container d-flex align-items-center">

            <nav class="navbar nav-menu navbar-expand-lg navbar-light col-12">
                <a id="title" href="index.html"><span> <strong> Eva </strong></span></a>
                <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#exampleModal">
                    <span class="ri-menu-line"></span> <!-- Buttom Open modal -->
                </button>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active"><a href="#index.html">Home</a> </li>
                        <li class="nav-item"><a href="#about">About</a> </li>
                        <li class="nav-item"><a href="#services">Features</a> </li>
                        <li class="nav-item"><a href="#pricing">Pricing</a> </li>
                        <li class="nav-item"><a href="#contact">Contact</a> </li>
                        <li class="get-started ml-3"><a class="text-center"
                                href="https://console.eva-demo.gncloud.nz">Sign-in</a></li>
                    </ul>
                </div>
            </nav>
        </div>

        <!-- Modal -->
        <div class="modal fade col-12 " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav-menu navbar-nav col-12">
                            <li class="nav-item active"><a href="#index.html">Home</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#about">About</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#services">Features</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#pricing">Pricing</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#contact">Contact</a> </li>
                            <li class="get-started m-auto"><a class="text-center mt-3"
                                    href="https://console.eva-demo.gncloud.nz"> <strong></strong> Sign-in</a></li>
                        </ul>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </header><!-- End Header -->


    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center mb-5">

        <div class="container col-xl-3 col-lg-4 col-md-8 col-11" id="SuccessMessage">

            <div class="row mb-3">
                <h1><i class="ri-checkbox-circle-line" id="succesicon"></i></h1>
                <h1 class="align-items-center ml-2">Success</h1>
            </div>
            <div class="mb-5">
                Your account has been created and an <strong> email</strong> was sent to you to activate it and create a new password. Follow the instructions then press the sign-in button.
            </div>
            <div class="text-center col-12">
                <a class="col-12" href="https://console.eva-demo.gncloud.nz" id="LinkButtom">Sign-in</a>
            </div>
        </div>

    </section>

    <!-- ======= Footer ======= -->
    <footer class="fixed-buttom" id="footer">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-lg-6 text-lg-left text-center">
                    <div class="copyright">
                        &copy; Copyright 2020 Eva, a product of <strong><a id="gnLinkFooter"
                                href="https://www.gnconsulting.co.nz/">GN Consulting Ltd</a></strong>. All Rights
                        Reserved
                    </div>
                </div>
                <div class="col-lg-6">
                    <nav class="footer-links text-lg-right text-center pt-2 pt-lg-0">
                        <a href="#">Privacy Policy</a>
                        <a href="#">Terms of Use</a>
                    </nav>
                </div>
            </div>
        </div>
    </footer><!-- End Footer -->

</body>

</html>