<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta content="width=device-width, initial-scale=1.0" name="viewport">

    <meta content="" name="description">
    <meta content="" name="keywords">

    <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap"
        rel="stylesheet">

    <!-- Add icon library -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <script src="https://js.stripe.com/v3/"></script>
</head>

<body>
    <!-- ======= Header ======= -->
    <header id="header" class="fixed-top d-flex align-items-center">
        <div class="container d-flex align-items-center">

            <nav class="navbar nav-menu navbar-expand-lg navbar-light col-12">
                <a id="title" href="index.html"><span> <strong> Eva </strong></span></a>
                <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#exampleModal">
                    <span class="ri-menu-line"></span> <!-- Buttom Open modal -->
                </button>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item active"><a href="#index.html">Home</a> </li>
                        <li class="nav-item"><a href="#about">About</a> </li>
                        <li class="nav-item"><a href="#services">Features</a> </li>
                        <li class="nav-item"><a href="#pricing">Pricing</a> </li>
                        <li class="nav-item"><a href="#contact">Contact</a> </li>
                        <li class="get-started ml-3"><a class="text-center"
                                href="https://console.eva-demo.gncloud.nz">Sign-in</a></li>
                    </ul>
                </div>
            </nav>
        </div>

        <!-- Modal -->
        <div class="modal fade col-12 " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav-menu navbar-nav col-12">
                            <li class="nav-item active"><a href="#index.html">Home</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#about">About</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#services">Features</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#pricing">Pricing</a> </li>
                            <li class="nav-item mt-1" data-dismiss="modal"><a href="#contact">Contact</a> </li>
                            <li class="get-started m-auto"><a class="text-center mt-3"
                                    href="https://console.eva-demo.gncloud.nz"> <strong></strong> Sign-in</a></li>
                        </ul>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </header><!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center mb-5">

        <div class="container col-12 col-md-5 col-lg-5 form-user">

            <div class="container">
                <div class="section-title" data-aos="fade-up">
                    <h2>Personal Information</h2>
                </div>
            </div>
            <!-- ======= Personal Information ======= -->
            <form action="#" method="post" role="form" class="email-form" id="email-form" data-aos="fade-up">

                <div class="row">
                    <div class="form-group col-6">
                        <input type="text" name="name" class="form-control" id="name" [(ngModel)]="inputName"
                            placeholder="Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div class="invalid-feedback">Please enter with your name</div>
                    </div>

                    <div class="form-group col-6">
                        <input type="text" name="lastName" class="form-control" id="inputLastName"
                            [(ngModel)]="inputLastName" placeholder="Last Name" data-rule="minlen:4"
                            data-msg="Please enter at least 4 chars" />
                        <div class="invalid-feedback">Please enter with your last name</div>
                    </div>
                </div>

                <div class="form-group">
                    <input type="text" name="country" class="form-control" id="inputCountry" [(ngModel)]="inputCountry"
                        placeholder="Country" data-rule="minlen:4" data-msg="Please enter with a country" />
                    <div class="invalid-feedback">Please enter with your country name</div>
                </div>

                <div class="row">

                    <div class="form-group col-4">
                        <input type="text" name="State" class="form-control" id="inputState" [(ngModel)]="inputState"
                            placeholder="State / Province" data-rule="minlen:4"
                            data-msg="Please enter with a country" />
                        <div class="invalid-feedback">Please enter with your State name</div>
                    </div>

                    <div class="form-group col-4">
                        <input type="text" name="city" class="form-control" id="inputCity" [(ngModel)]="inputCity"
                            placeholder="City" data-rule="minlen:4" data-msg="Please enter with a city" />
                        <div class="invalid-feedback">Please enter with your city</div>
                    </div>
                    <div class="form-group col-4">
                        <input type="text" name="zipCode" class="form-control" id="inputZipCode"
                            [(ngModel)]="inputZipCode" placeholder="Zip Code" data-rule="minlen:4"
                            data-msg="Please enter with a zip code" />
                        <div class="invalid-feedback">Please enter with your zip code</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" name="address" class="form-control" id="inputAddress" [(ngModel)]="inputAddress"
                        placeholder="Address" data-rule="minlen:4" data-msg="Please enter with your address" />
                    <div class="invalid-feedback">Please enter with your address</div>
                </div>

                <div class="row align-items-center">

                    <div class="form-group col-6">
                        <input type="number" name="deviceNumber" class="form-control" id="InputdeviceNumber"
                            [(ngModel)]="inputDeviceNumber" (keyup)="DeviceNumberChange($event)"
                            placeholder="Device Number" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div class="invalid-feedback">Choose number of devices</div>
                    </div>
                    <div class="form-group col-6 text-center">
                        <div class="row justify-content-center">
                            <p>tier: &#160;</p>
                            <h4 id="tier">{{typeofAccount}}</h4>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" name="inputEmail" [(ngModel)]="inputEmail" id="inputEmail"
                        placeholder="E-mail" data-rule="email" data-msg="Please enter a valid email" />
                    <div class="invalid-feedback">Email is invalid or already exist</div>
                </div>

                <!-- ======= <div *ngIf="inputDeviceNumber > 1 || typeofAccount != AlwaysFree"> ======= -->



                <div class="container mt-4">
                    <div class="section-title" data-aos="fade-up">
                        <h2>Payment method</h2>
                    </div>
                </div>


                <!-- ======= Hero Section ======= 
                    <div class="row mb-5 align-items-center" data-aos="fade-up">
                        <div class="container col-12 col-md-6">

                            <div class="form-group mt-2">
                                <input type="number" name="cardNumber" class="form-control" id="inputCardNumber"
                                    [(ngModel)]="inputCardNumber" placeholder="CARD NUMBER" data-rule="minlen:4"
                                    data-msg="Please enter at least 4 chars" />
                                <div class="invalid-feedback">Please enter with your Card Number</div>
                            </div>

                            <div class="row ">

                                <div class="form-group col-6">
                                    <input type="text" name="expireDate" class="form-control" id="inputExpireDate"
                                        [(ngModel)]="inputExpireDate" placeholder="EXPIRE DATE" data-rule="minlen:4"
                                        data-msg="Please enter at least 4 chars" />
                                    <div class="invalid-feedback">Please enter with expire data</div>
                                </div>
                                <div class="form-group col-6">
                                    <input type="text" name="secureCode" class="form-control" id="inputSecureCode"
                                        [(ngModel)]="inputSecureCode" placeholder="SECURE CODE" data-rule="minlen:4"
                                        data-msg="Please enter at least 4 chars" (click)="swichCard()"
                                        (focusout)="onFocusOutEvent()" />
                                    <div class="invalid-feedback">Please enter with your code</div>
                                </div>

                            </div>

                            <div class="form-group">
                                <input type="text" name="cardName" class="form-control" id="inputCardName"
                                    [(ngModel)]="inputCardName" placeholder="CARD HOLDER" data-rule="minlen:4"
                                    data-msg="Please enter at least 4 chars" />
                                <div class="invalid-feedback">Please enter with card holder</div>
                            </div>

                        </div>

                        <span class="iconify" data-icon="mdi:contactless-payment" data-inline="false"></span>
                        <div class="flip-card">
                            <div class="flip-card-inner" id="flip-card-inner">
                                <div class="flip-card-front" id="card-image">

                                    <div class="cardtittle col-12 my-3 ml-2 text-left"> GN CONSULTING
                                    </div>
                                    <div class="col-12 ml-1 text-left">

                                        <div class="row align-items-center mt-3 ml-1" id="chipImage">
                                            <img src="assets/img/card-chip.png" id="cardchip" class="img-fluid animated"
                                                alt="">
                                            <img src="assets/img/wireless.png" id="cardicon"
                                                class="img-fluid animated ml-2" alt="">
                                        </div>

                                    </div>

                                    <input type="number" name="cardNumberImage"
                                        class="form-control text-left ml-2 mt-1 " id="inputCardnumberCard"
                                        [(ngModel)]="inputCardNumber" placeholder="5453    5251    5657    5859"
                                        disabled />

                                    <div class="row" id="teste">
                                        <div id="cardCol" class="col-8">
                                            <div class="row align-items-center ml-1 col-12" id="rowValid">
                                                <div class="text-white validThru text-left">EXPIRE DATE:</div>

                                                <input type="text" name="expireDateImage" class="form-control"
                                                    id="inputExpireDateCard" [(ngModel)]="inputExpireDate"
                                                    placeholder="00 / 00" disabled />

                                            </div>

                                            <input type="text" name="cardNameImage" class="form-control ml-2"
                                                id="inputCardNameCard" [(ngModel)]="inputCardName"
                                                placeholder="CARD HOLDER NAME" disabled />
                                        </div>

                                        <img src="assets/img/GnLogo.png" id="logoCard"
                                            class="img-fluid animated ml-4 mt-2" alt="">
                                    </div>

                                </div>

                                <div class="flip-card-back" id="card-image">
                                    <div class="col-12 mt-4" id="blackbar"></div>
                                    <div class="form-group col-12 mt-2">
                                        <div class="text-white text-right mb-1 mr-3" id="CCV">CCV</div>
                                        <input type="text" name="secureCodeImage" class="form-control text-right"
                                            id="inputSecureCodeCard" [(ngModel)]="inputSecureCode" placeholder="000"
                                            data-rule="minlen:4" data-msg="Please enter at least 4 chars" disabled />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->

                <!-- =======  </div> ======= -->
                <div id="card-element" class="form-control mt-2" data-aos="fade-up">
                    <!-- Elements will create input elements here -->
                </div>

                <!-- We'll put the error messages in this element -->
                <div id="card-element-errors" role="alert"></div>

                <div class="form-check text-center my-4" data-aos="fade-up">
                    <input class="form-check-input" type="checkbox" value=""
                        onchange="document.getElementById('formButtom').disabled = !this.checked;"
                        id="flexCheckIndeterminate">
                    <label class="form-check-label" for="flexCheckIndeterminate">
                        I have read and agree with <a href="#termsModal" data-toggle="modal"
                            data-target="#termsModal">Privacy Policy</a>
                    </label>
                </div>

                <div class="text-center h-100 justify-content-center align-items-center mt-3" id="divButtom"
                    data-aos="fade-up"><button class="row align-items-center" id="formButtom"
                        (click)="messageValidator()" type="submit" disabled> <i id="loading"></i>
                        <div id="load">Create Account</div>
                    </button></div>

            </form>


            <!-- Email Message -->
            <div class="alert hide">
                <span class="ri-checkbox-circle-line"></span>
                <span class="msg">Your message has been sent. Thank you</span>
                <span class="close-btn">
                    <span class="ri-close-fill"></span>
                </span>
            </div>
            <!-- Error Email Message -->
            <div class="alert-error hide">
                <span class="ri-error-warning-line"></span>
                <span class="msg">Please check all fields and try again</span>
                <span class="close-btn">
                    <span class="ri-close-fill"></span>
                </span>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content-terms">
                        <div class="modal-header">
                            <h5 class="modal-title" id="ModalLabel">Privacy Policy</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p> &#160;&#160;The information that we collect is to offer a better experience to the user,
                                it will not
                                be public, and only the user will be able to see and change that information.
                                The user information will appear in our website only to improve the interface make it
                                more
                                friendly to users and easier to navigate through the website. <br>
                                &#160;&#160;Credit card information will not be public and impossible to get that
                                information, that will only be use to monthly payment
                                and that information is encrypted so no one can access that information only the user
                                can alter to a new card if is needed.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section><!-- End Hero -->

    <!-- ======= Footer ======= -->
    <footer class="fixed-buttom" id="footer">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-lg-6 text-lg-left text-center">
                    <div class="copyright">
                        &copy; Copyright 2020 Eva, a product of <strong><a id="gnLinkFooter"
                                href="https://www.gnconsulting.co.nz/">GN Consulting Ltd</a></strong>. All Rights
                        Reserved
                    </div>
                </div>
                <div class="col-lg-6">
                    <nav class="footer-links text-lg-right text-center pt-2 pt-lg-0">
                        <a href="#">Privacy Policy</a>
                        <a href="#">Terms of Use</a>
                    </nav>
                </div>
            </div>
        </div>
    </footer><!-- End Footer -->

</body>

</html>