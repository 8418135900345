import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

//Stripe vars
let stripe = Stripe('NGINX_REPLACE_PK_API_KEY');
let elements = stripe.elements();

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  constructor(private httpClient: HttpClient, private router: Router) { }


  //Redirect to succes page if email is created
  redirect() {
    this.router.navigate(['./successCreate']);
  }

  backendUrl = "NGINX_REPLACE_BACKEND_API_SERVER";

  public inputTypeAccount: string = "";

  //User variables
  public inputName: string = "";
  public inputLastName: string = "";
  public inputCountry: string = "";
  public inputState: string = "";
  public inputCity: string = "";
  public inputZipCode: string = "";
  public inputAddress: string = "";
  public inputDeviceNumber: number = null
  public inputEmail: string = "";

  //Card variables 
  public inputCardNumber = null
  public inputExpireDate = null
  public inputSecureCode = null
  public inputCardName = null

  //Element to create card input
  public card = elements.create('card', {});
  public paymentMethodId: string
  public paymentMethodResponse = null

  userAccount = { name: '', lastName: '', country: '', State: "", city: '', zipCode: '', address: '', deviceNumber: null, email: '', paymentMethodId: '' };

  //Get localStorage item setted in home component
  typeofAccount = localStorage.getItem('accountType');
  AlwaysFree = "Always Free"

  //This Function change the name price package
  DeviceNumberChange(event) {
    this.inputDeviceNumber = event.target.value;
    if (this.inputDeviceNumber >= 2 && this.inputDeviceNumber <= 19) {
      this.typeofAccount = "Start-Up"
    } else if (this.inputDeviceNumber > 19) {
      this.typeofAccount = "Professional"
    } else {
      this.typeofAccount = "Always Free"
    }
  }

  //Function to check informatin and change color of input depends on status
  VerifyFild(checkInput: string) {
    var Input = checkInput
    var name_lenght = $(Input).val().length;
    if (name_lenght < 1) {
      $(Input).removeClass("is-valid");
      $(Input).addClass("is-invalid");
    } else {
      $(Input).removeClass("is-invalid");
      $(Input).addClass("is-valid");
    }
  }

  /*//flip card when it focous
  swichCard() {
    $('#flip-card-inner').addClass("inputSecureCodes");
  }

  //flip back card when it outfocous
  onFocusOutEvent() {
    $('#flip-card-inner').removeClass("inputSecureCodes");
  }
  */

  //This Function will create a new PaymentMethod
  createPaymentMethod(cardElement) {
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          this.showErrorMessage();
          this.displayError(event);
        } else {
          let paymentMethodId = {
            paymentMethodId: result.paymentMethod.id
          }
          this.paymentMethodId = paymentMethodId.paymentMethodId

          console.log(this.paymentMethodId)
          //Calling function to send post 
          this.createAccount();
        }
      });

  }

  StripeElements() {
    //Style from strip card input
    var style = {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "17px",
        "::placeholder": {
          color: "#8e8e8e"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    //Element to create card input
    this.card.mount('#card-element');

    //Display error on change
    this.card.on('change', function (event) {
      displayError(event);
    });

    function displayError(event) {
      let displayError = document.getElementById('card-element-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    }

  }

  displayError(event) {
    let displayError = document.getElementById('card-element-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  }


  ngOnInit(): void {

    this.StripeElements();

    //use to nav toggler be active
    $('.navbar-toggler').click(function () {
      $('#exampleModal').appendTo("body");
    })

    // Validation Form will show on time if input is valid

    $('#name').focusout(function () {
      VerifyFild('#name');
    })

    $('#inputLastName').focusout(function () {
      VerifyFild('#inputLastName');
    })

    $('#inputCountry').focusout(function () {
      VerifyFild('#inputCountry');
    })

    $('#inputState').focusout(function () {
      VerifyFild('#inputState');
    })

    $('#inputCity').focusout(function () {
      VerifyFild('#inputCity');
    })

    $('#inputZipCode').focusout(function () {
      VerifyFild('#inputZipCode');
    })

    $('#inputAddress').focusout(function () {
      VerifyFild('#inputAddress');
    })

    $('#InputdeviceNumber').focusout(function () {
      check_deviceNumber();
    })

    $('#inputEmail').focusout(function () {
      check_email();
    })

    function VerifyFild(checkInput: string) {
      var Input = checkInput
      var name_lenght = $(Input).val().length;
      if (name_lenght < 1) {
        $(Input).removeClass("is-valid");
        $(Input).addClass("is-invalid");
      } else {
        $(Input).removeClass("is-invalid");
        $(Input).addClass("is-valid");
      }
    }

    function check_deviceNumber() {
      var name_lenght = $("#InputdeviceNumber").val();
      if (name_lenght < 1) {
        $('#InputdeviceNumber').removeClass("is-valid");
        $('#InputdeviceNumber').addClass("is-invalid");
      } else {
        $('#InputdeviceNumber').removeClass("is-invalid");
        $('#InputdeviceNumber').addClass("is-valid");
      }
    }

    function check_email() {
      var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);

      if (pattern.test($("#inputEmail").val())) {
        $('#inputEmail').removeClass("is-invalid");
        $('#inputEmail').addClass("is-valid");
      } else {
        $('#inputEmail').removeClass("is-valid");
        $('#inputEmail').addClass("is-invalid");
      }
    }

  }

  createAccount() {

    this.userAccount.name = this.inputName //Filling the object
    this.userAccount.lastName = this.inputLastName
    this.userAccount.country = this.inputCountry
    this.userAccount.State = this.inputState
    this.userAccount.city = this.inputCity
    this.userAccount.zipCode = this.inputZipCode
    this.userAccount.address = this.inputAddress
    this.userAccount.deviceNumber = Number(this.inputDeviceNumber)
    this.userAccount.email = this.inputEmail
    this.userAccount.paymentMethodId = this.paymentMethodId

    console.log(this.userAccount)


    var url = this.backendUrl + "/billing/newClient";

    this.httpClient.post(url, this.userAccount)
      //Sending
      .subscribe(

        (data: any) => {

          var postResponse = data['responseStatus']
          console.log("Status:", postResponse)

          if (postResponse == "AccountCreated") {
            this.redirect();
            //call functions to post informations and give a feedback
            this.showSuccessMessage();

            $("#email-form")[0].reset();
            this.inputName = "";
            this.inputLastName = "";
            this.inputCountry = "";
            this.inputState = "";
            this.inputCity = "";
            this.inputZipCode = "";
            this.inputAddress = "";
            this.inputEmail = "";
            this.inputDeviceNumber = null;
          } else if (postResponse == "emailAlreadyExist") {
            this.showErrorMessageOnly();
            this.emailAlreadyExist();
          } else {
            this.showErrorMessageOnly();
          }
        },
        err => {
          console.log("Error:", err)
          if (err) {
            this.showErrorMessageOnly()
          }
        }
      )
  }

  //Shows Fail or Success message
  messageValidator() {

    //Load buttom function 
    $('#load').text("Loading");
    $('#loading').addClass("fa");
    $('#loading').addClass("fa-circle-o-notch");
    $('#loading').addClass("fa-spin");
    $('#load').addClass("ml-2");


    var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);
    var regex = /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[0-9]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;
    //check if all inputs is corret to send post request
    if (this.inputName.length >= 1
      && pattern.test(this.inputEmail)
      && this.inputLastName.length >= 1
      && this.inputCountry.length >= 1
      && this.inputState.length >= 1
      && this.inputCity.length >= 1
      && this.inputZipCode.length >= 1
      && this.inputAddress.length >= 1
      && this.inputDeviceNumber >= 1) {

      //Call function to create stripe payment Method
      this.createPaymentMethod(this.card)

    } else {
      this.showErrorMessage();
    }
  }

  //Show success message after click on form buttom
  showSuccessMessage() {
    $('.alert').removeClass("hide");
    $('.alert').addClass("show");
    $('.alert').addClass("showAlert");
    setTimeout(function () {
      $('.alert').addClass("hide");
      $('.alert').removeClass("show");
    }, 5000)//Hide alert automaticallu after 5 sec
    $('.close-btn').click(function () { //Close Alert when click on the button
      $('.alert').addClass("hide");
      $('.alert').removeClass("show");
    })
  }

  showErrorMessage() {
    //Show error message after click on form buttom
    $('.alert-error').removeClass("hide");
    $('.alert-error').addClass("show");
    $('.alert-error').addClass("showAlert");
    setTimeout(function () {
      $('.alert-error').addClass("hide");
      $('.alert-error').removeClass("show");
    }, 5000)//Hide alert automaticallu after 5 sec
    $('.close-btn').click(function () { //Close Alert when click on the button
      $('.alert-error').addClass("hide");
      $('.alert-error').removeClass("show");
    })

    //Cancel Load buttom function
    $('#load').text("Create Account");
    $('#loading').removeClass("fa");
    $('#loading').removeClass("fa-circle-o-notch");
    $('#loading').removeClass("fa-spin");
    $('#load').removeClass("ml-2");

    //Call functions to show error color if field is empity
    this.VerifyFild('#name');
    this.VerifyFild('#inputLastName');
    this.VerifyFild('#inputCountry');
    this.VerifyFild('#inputCity');
    this.VerifyFild('#inputState');
    this.VerifyFild('#inputZipCode');
    this.VerifyFild('#inputAddress');

    //Show error if email is not valid
    var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);

    if (pattern.test($("#inputEmail").val())) {
      $('#inputEmail').removeClass("is-invalid");
      $('#inputEmail').addClass("is-valid");
    } else {
      $('#inputEmail').removeClass("is-valid");
      $('#inputEmail').addClass("is-invalid");
    }

    //Show error if country is empty
    var InputdeviceNumber_lenght = $("#InputdeviceNumber").val();
    if (InputdeviceNumber_lenght < 1) {
      $('#InputdeviceNumber').removeClass("is-valid");
      $('#InputdeviceNumber').addClass("is-invalid");
    } else {
      $('#InputdeviceNumber').removeClass("is-invalid");
      $('#InputdeviceNumber').addClass("is-valid");
    }

  }
  //show only message
  showErrorMessageOnly() {
    //Show error message after click on form buttom
    $('.alert-error').removeClass("hide");
    $('.alert-error').addClass("show");
    $('.alert-error').addClass("showAlert");
    setTimeout(function () {
      $('.alert-error').addClass("hide");
      $('.alert-error').removeClass("show");
    }, 5000)//Hide alert automaticallu after 5 sec
    $('.close-btn').click(function () { //Close Alert when click on the button
      $('.alert-error').addClass("hide");
      $('.alert-error').removeClass("show");
    })

    //Cancel Load buttom function
    $('#load').text("Create Account");
    $('#loading').removeClass("fa");
    $('#loading').removeClass("fa-circle-o-notch");
    $('#loading').removeClass("fa-spin");
    $('#load').removeClass("ml-2");

  }
  //if email already exist turn email field in red
  emailAlreadyExist() {
    $('#inputEmail').removeClass("is-valid");
    $('#inputEmail').addClass("is-invalid");
  }
}




