import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as $ from 'jquery'
import AOS from 'aos'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Eva';

  public inputName:string = "";
  public inputEmail:string = "";
  public inputSubject: string = "";
  public inputMessage:string = "";

  email = { name: '', email: '', option: '', message: '' };

  ngOnInit(){


    $('.navbar-toggler').click(function(){
      $('#exampleModal').appendTo("body");
    })

  // Validation Form will show on time if input is valid
    $('#inputName').focusout(function(){
      check_name();
    })

    $('#inputEmail').focusout(function(){
      check_email();
    })

    $('#inputSubject').focusout(function(){
      check_subject();
    })

    $('#inputMessage').focusout(function(){
      check_message();
    })

    function check_name(){
      var name_lenght = $("#inputName").val().length;
      if(name_lenght < 1){
        $('#inputName').removeClass("is-valid");
        $('#inputName').addClass("is-invalid");
      }else{
        $('#inputName').removeClass("is-invalid");
        $('#inputName').addClass("is-valid");
      }
    }

    function check_email(){
      var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);

      if(pattern.test($("#inputEmail").val())){
        $('#inputEmail').removeClass("is-invalid");
        $('#inputEmail').addClass("is-valid");
      }else{
        $('#inputEmail').removeClass("is-valid");
        $('#inputEmail').addClass("is-invalid");
      }
    }

    function check_subject(){
      var name_lenght = $("#inputSubject").val();
      if(name_lenght == null){
        $('#inputSubject').removeClass("is-valid");
        $('#inputSubject').addClass("is-invalid");
      }else{
        $('#inputSubject').removeClass("is-invalid");
        $('#inputSubject').addClass("is-valid");
      }
    }

    function check_message(){
      var message_lenght = $("#inputMessage").val().length;
      if(message_lenght < 1){
        $('#inputMessage').removeClass("is-valid");
        $('#inputMessage').addClass("is-invalid");
      }else{
        $('#inputMessage').removeClass("is-invalid");
        $('#inputMessage').addClass("is-valid");
      }
    }

  // Smooth scroll for the navigation menu and links with .scrollto classes
  var scrolltoOffset = $('#header').outerHeight() - 15;
  $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function(e) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      if (target.length) {
        e.preventDefault();

        var scrollto = target.offset().top - scrolltoOffset;

        if ($(this).attr("href") == '#header') {
          scrollto = 0;
        }

        $('html, body').animate({
          scrollTop: scrollto
        }, 1000);

        if ($(this).parents('.nav-menu, .mobile-nav').length) {
          $('.nav-menu .active, .mobile-nav .active').removeClass('active');
          $(this).closest('li').addClass('active');
        }

        if ($('body').hasClass('mobile-nav-active')) {
          $('body').removeClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
          $('.mobile-nav-overly').fadeOut();
        }
        return false;
      }
    }
  });

  // Activate smooth scroll on page load with hash links in the url
  $(document).ready(function() {
    if (window.location.hash) {
      var initial_nav = window.location.hash;
      if ($(initial_nav).length) {
        var scrollto = $(initial_nav).offset().top - scrolltoOffset;
        $('html, body').animate({
          scrollTop: scrollto
        }, 1000);
      }
    }
  });

  // Navigation active state on scroll
  var nav_sections = $('section');
  var main_nav = $('.nav-menu, #mobile-nav');

  $(window).on('scroll', function() {
    var cur_pos = $(this).scrollTop() + 200;

    nav_sections.each(function() {
      var top = $(this).offset().top,
        bottom = top + $(this).outerHeight();

      if (cur_pos >= top && cur_pos <= bottom) {
        if (cur_pos <= bottom) {
          main_nav.find('li').removeClass('active');
        }
        main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
      }
      if (cur_pos < 300) {
        $(".nav-menu ul:first li:first").addClass('active');
      }
    });
  });

  // Toggle .header-scrolled class to #header when page is scrolled
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    } else {
      $('#header').removeClass('header-scrolled');
    }
  });

  if ($(window).scrollTop() > 100) {
    $('#header').addClass('header-scrolled');
  }

  // Back to top button
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });

  $('.back-to-top').click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  /*
  // jQuery counterUp
  $('[data-toggle="counter-up"]').counterUp({
    delay: 10,
    time: 1000
  });

  // Testimonials carousel (uses the Owl Carousel library)
  $(".testimonials-carousel").owlCarousel({
    autoplay: true,
    dots: true,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 1
      },
      900: {
        items: 2
      }
    }
  });

  // Porfolio isotope and filter
  $(window).on('load', function() {
    var portfolioIsotope = $('.portfolio-container').isotope({
      itemSelector: '.portfolio-item',
      layoutMode: 'fitRows'
    });

    $('#portfolio-flters li').on('click', function() {
      $("#portfolio-flters li").removeClass('filter-active');
      $(this).addClass('filter-active');

      portfolioIsotope.isotope({
        filter: $(this).data('filter')
      });
      aos_init();
    });

    // Initiate venobox (lightbox feature used in portofilo)
    $(document).ready(function() {
      $('.venobox').venobox();
    });
  });

  // Portfolio details carousel
  $(".portfolio-details-carousel").owlCarousel({
    autoplay: true,
    dots: true,
    loop: true,
    items: 1
  });   */

  // Init AOS
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  }

  constructor(private httpClient:HttpClient){}

  sendEmail(){

    if(this.inputSubject == "Eva: Support"){
      this.inputSubject = "eva-support"
    }else if(this.inputSubject == "Eva: Sales"){
      this.inputSubject = "eva-sales"
    }

    this.email.name = this.inputName //Filling the object
    this.email.email = this.inputEmail
    this.email.option = this.inputSubject.toLocaleLowerCase()
    this.email.message = this.inputMessage
    console.log(this.email)

    this.httpClient.post(`https://a2oviywj85.execute-api.ap-southeast-2.amazonaws.com/form-eva`, this.email) //Sending
    .subscribe(
      (data:any)=>{
        console.log(data)
      }
    )
    $("#email-form")[0].reset();
    this.inputName = "";
    this.inputEmail = "";
    this.inputSubject = "";
    this.inputMessage = "";
  }
  //Shows Fail or Success message
  messageValidator(){
    var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);
    console.log(this.inputEmail)

    if (this.inputName.length >= 1 && pattern.test(this.inputEmail) &&
      this.inputSubject != "" && this.inputMessage.length >= 1){
      this.showSuccessMessage();
      this.sendEmail();
    }else{
      this.showErrorMessage();
    }
  }


  //Show success message after click on form buttom
  showSuccessMessage(){
      $('.alert').removeClass("hide");
      $('.alert').addClass("show");
      $('.alert').addClass("showAlert");
      setTimeout(function(){
        $('.alert').addClass("hide");
        $('.alert').removeClass("show");
      }, 5000)//Hide alert automaticallu after 5 sec
    $('.close-btn').click(function(){ //Close Alert when click on the button
      $('.alert').addClass("hide");
      $('.alert').removeClass("show");
    })
  }

  showErrorMessage(){
  //Show error message after click on form buttom
    $('.alert-error').removeClass("hide");
    $('.alert-error').addClass("show");
    $('.alert-error').addClass("showAlert");
    setTimeout(function(){
      $('.alert-error').addClass("hide");
      $('.alert-error').removeClass("show");
    }, 5000)//Hide alert automaticallu after 5 sec
  $('.close-btn').click(function(){ //Close Alert when click on the button
    $('.alert-error').addClass("hide");
    $('.alert-error').removeClass("show");
  })
       //Show error if name is empty
        var name_lenght = $("#inputName").val().length;
        if(name_lenght < 1){
          $('#inputName').removeClass("is-valid");
          $('#inputName').addClass("is-invalid");
        }else{
          $('#inputName').removeClass("is-invalid");
          $('#inputName').addClass("is-valid");
        }
        //Show error if email is not valid
          var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);

          if(pattern.test($("#inputEmail").val())){
            $('#inputEmail').removeClass("is-invalid");
            $('#inputEmail').addClass("is-valid");
          }else{
            $('#inputEmail').removeClass("is-valid");
            $('#inputEmail').addClass("is-invalid");
          }
        //Show error if subject is empty
          var subject_lenght = $("#inputSubject").val();
           if(subject_lenght == null){
             $('#inputSubject').removeClass("is-valid");
             $('#inputSubject').addClass("is-invalid");
           }else{
             $('#inputSubject').removeClass("is-invalid");
             $('#inputSubject').addClass("is-valid");
           }
      
        //Show error if message is empty
        var message_lenght = $("#inputMessage").val().length;
        if(message_lenght < 1){
          $('#inputMessage').removeClass("is-valid");
          $('#inputMessage').addClass("is-invalid");
        }else{
          $('#inputMessage').removeClass("is-invalid");
          $('#inputMessage').addClass("is-valid");
        }
}

}
