<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap" rel="stylesheet">

</head>

<body>

  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center">

      <nav class="navbar nav-menu navbar-expand-lg navbar-light col-12">
      <a id="title" href="index.html"><span> <strong> Eva </strong></span></a>
        <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#exampleModal">
          <span class="ri-menu-line"></span>  <!-- Buttom Open modal -->
        </button>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active"><a href="#index.html">Home</a> </li>
            <li class="nav-item"><a href="#about">About</a> </li>
            <li class="nav-item"><a href="#services">Features</a> </li>
            <li class="nav-item"><a href="#pricing">Pricing</a> </li>
            <li class="nav-item"><a href="#contact" >Contact</a> </li>
            <li class="get-started ml-3"><a class="text-center" href="https://console.eva-demo.gncloud.nz">Sign-in</a></li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- Modal -->
    <div class="modal fade col-12 " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <ul class="nav-menu navbar-nav col-12">
              <li class="nav-item active"><a href="#index.html">Home</a> </li>
              <li class="nav-item mt-1" data-dismiss="modal"><a href="#about">About</a> </li>
              <li class="nav-item mt-1" data-dismiss="modal"><a href="#services">Features</a> </li>
              <li class="nav-item mt-1" data-dismiss="modal"><a href="#pricing">Pricing</a> </li>
              <li class="nav-item mt-1" data-dismiss="modal"><a href="#contact" >Contact</a> </li>
              <li class="get-started m-auto"><a class="text-center mt-3" href="https://console.eva-demo.gncloud.nz"> <strong></strong> Sign-in</a></li>
            </ul>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">Unleash your IoT</h1>
          <h2 data-aos="fade-up" data-aos-delay="400">Integrate with the major cloud services, while we take care of device management</h2>
          <div data-aos="fade-up" data-aos-delay="800">
            <a href="#about" class="btn-get-started scrollto">Get Started</a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
          <img src="assets/img/hero-img.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>About Us</h2>
        </div>

        <div class="row content">
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
            <p>
              Eva is an open-source IoT solution for Device Management, Data Ingestion, Analytics and Visualization, and Cloud Integration.  Eva allows you to:
            </p>
            <ul>
              <li><i class="ri-check-double-line"></i> Manage a fleet of connected devices</li>
              <li><i class="ri-check-double-line"></i> Securely collect telemetry</li>
              <li><i class="ri-check-double-line"></i> Integrate with the cloud</li>
            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <p>
              Eva uses an intuitive UI for connecting, developing and deploying devices which will, in turn, allow you to harvest data and ingest it into pipelines for further processing. With a rules engine, you can augment, enrich and even perform firmware upgrades over the air. Finally, you can also create and securely share beautiful dashboards for your customers.
            </p>
            <a href="#services" class="btn-learn-more scrollto">Learn More</a>
          </div>
        </div>

      </div>
    </section><!-- End About Us Section -->

    <!-- ======= Counts Section ======= -->
<!--    <section id="counts" class="counts">-->
<!--      <div class="container">-->

<!--        <div class="row">-->
<!--          <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">-->
<!--&lt;!&ndash;            <img src="assets/img/counts-img.svg" alt="" class="img-fluid">&ndash;&gt;-->
<!--          </div>-->

<!--          <div class="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">-->
<!--            <div class="content d-flex flex-column justify-content-center">-->
<!--              <div class="row">-->
<!--                <div class="col-md-6 d-md-flex align-items-md-stretch">-->
<!--                  <div class="count-box">-->
<!--                    <i class="icofont-simple-smile"></i>-->
<!--                    <span data-toggle="counter-up">65</span>-->
<!--                    <p><strong>Happy Clients</strong> consequuntur voluptas nostrum aliquid ipsam architecto ut.</p>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-md-6 d-md-flex align-items-md-stretch">-->
<!--                  <div class="count-box">-->
<!--                    <i class="icofont-document-folder"></i>-->
<!--                    <span data-toggle="counter-up">85</span>-->
<!--                    <p><strong>Projects</strong> adipisci atque cum quia aspernatur totam laudantium et quia dere tan</p>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-md-6 d-md-flex align-items-md-stretch">-->
<!--                  <div class="count-box">-->
<!--                    <i class="icofont-clock-time"></i>-->
<!--                    <span data-toggle="counter-up">12</span>-->
<!--                    <p><strong>Years of experience</strong> aut commodi quaerat modi aliquam nam ducimus aut voluptate non vel</p>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="col-md-6 d-md-flex align-items-md-stretch">-->
<!--                  <div class="count-box">-->
<!--                    <i class="icofont-award"></i>-->
<!--                    <span data-toggle="counter-up">15</span>-->
<!--                    <p><strong>Awards</strong> rerum asperiores dolor alias quo reprehenderit eum et nemo pad der</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>&lt;!&ndash; End .content&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </section>&lt;!&ndash; End Counts Section &ndash;&gt;-->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Features</h2>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box col-12" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bx bxl-dribbble"></i></div>
              <h4 class="title"><a href="">Device Management</a></h4>
              <p class="description">Control and bootstrap devices</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box col-12" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="bx bx-file"></i></div>
              <h4 class="title"><a href="">Authentication</a></h4>
              <p class="description">Secure your communication using keys and/or certificates</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box col-12" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><i class="bx bx-tachometer"></i></div>
              <h4 class="title"><a href="">Process Rules</a></h4>
              <p class="description">Design your process using code or our interactive UI</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box col-12" data-aos="fade-up" data-aos-delay="400">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href="">Dashboard</a></h4>
              <p class="description">Create beautiful visualization for your data</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Services Section -->


    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing">
      <div class="container">

        <div class="section-title">
          <h2>Pricing</h2>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="box" data-aos="zoom-in-right" data-aos-delay="200">
              <h3>Always Free</h3>
              <h4><span>Each, from NZD </span><sup>$</sup>0<span> / month</span></h4>
              <ul>
                <li>One device, always free</li>
                <li class="na">-</li>
                <li class="na">-</li>
                <li class="na">-</li>
                <li class="na">-</li>
              </ul>
              <div class="btn-wrap">
                <a href="/create" (click)="AlwaysFreeAccount()" class="btn-buy">Create your account</a>
              </div>
            </div>
          </div>
          

          <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
              <h3>Start-up</h3>
              <h4><span>Each, from NZD </span><sup>$</sup>2<sup>.25</sup><span> / month</span></h4>
              <ul>
                <li>Up to 19 devices</li>
                <li>Unlimited telemetry ingestion</li>
                <li>Unlimited rules engine minutes</li>
                <li>API Access</li>
                <li class="na">-</li>
              </ul>
              <div class="btn-wrap">
                <a href="/create" (click)="StartUpAccount()" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div class="box" data-aos="zoom-in-left" data-aos-delay="200">
              <h3>Professional</h3>
              <h4><span>Each, from NZD </span><sup>$</sup>1<sup>.25</sup><span> / month</span></h4>
              <ul>
                <li>From 20 devices</li>
                <li>Unlimited telemetry ingestion</li>
                <li>Unlimited rules engine minutes</li>
                <li>API Access</li>
                <li>Whitelabel</li>
              </ul>
              <div class="btn-wrap">
                <a href="/create" (click)="ProfessionalAccount()" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Pricing Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Contact Us</h2>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="contact-about">
              <h3>Eva</h3>
              <p>Use the form on the right to get in touch with us, or feel free to use one of our contact options.</p>
              <div class="social-links">
                <a href="#" class="twitter"><i class="ri-twitter-fill"></i></a>
                <a href="#" class="facebook"><i class="ri-facebook-fill"></i></a>
                <a href="#" class="instagram"><i class="ri-instagram-line"></i></a>
                <a href="#" class="linkedin"><i class="ri-linkedin-box-fill"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
            <div class="info">
              <div>
                <i class="ri-map-pin-line"></i>
                <p>Trentham<br>Upper Hutt, NZ 5018</p>
              </div>

              <div>
                <i class="ri-mail-send-line"></i>
                <p>support@gncloud.nz</p>
              </div>

              <div>
                <i class="ri-phone-line"></i>
                <p>New Zealand: (04)- 895 5488</p>
                <i class="ri-phone-line"></i>
                <p>Brazil: (081)- 4042 0105</p>
              </div>

            </div>
          </div>

          <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
            <form action="#" method="post" role="form" class="email-form" id="email-form">
              <div class="form-group">
                <input type="text" name="name" class="form-control" id="inputName" [(ngModel)]="inputName" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="invalid-feedback">Please enter with your name</div>
              </div>
              <div class="form-group">
                <input type="email" class="form-control" name="inputEmail" [(ngModel)]="inputEmail" id="inputEmail" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="invalid-feedback">Please enter with a valid email</div>
              </div>

              <div class="form-group">
                <select class="form-control" id="inputSubject" name="inputSubject" [(ngModel)]="inputSubject">           
                  <option>Eva: Sales</option>
                  <option>Eva: Support</option>
                </select>
                <div class="invalid-feedback">Please choose one of the topics</div>
              </div>

              <div class="form-group">
                <textarea class="form-control" name="inputMessage" [(ngModel)]="inputMessage" id="inputMessage"  rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                <div class="invalid-feedback">Please write a short message</div>
              </div>

              <div class="text-center"><button id="formButtom" type="submit" (click)="messageValidator()">Send Message</button></div>
            </form>
          </div>
        </div>
      </div>
    </section><!-- End Contact Section -->

    <!-- Email Message -->
    <div class="alert hide">
      <span class="ri-checkbox-circle-line"></span>
      <span class="msg">Your message has been sent. Thank you</span>
      <span class="close-btn">
        <span class="ri-close-fill"></span>
      </span>
    </div>
    <!-- Error Email Message -->
    <div class="alert-error hide">
      <span class="ri-error-warning-line"></span>
      <span class="msg">Please fill all empty filds and try again</span>
      <span class="close-btn">
        <span class="ri-close-fill"></span>
      </span>
    </div>

    <!-- End of Email Message -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6 text-lg-left text-center">
          <div class="copyright">
            &copy; Copyright 2020 Eva, a product of <strong><a id="gnLinkFooter" href="https://www.gnconsulting.co.nz/">GN Consulting Ltd</a></strong>. All Rights Reserved
          </div>
        </div>
        <div class="col-lg-6">
          <nav class="footer-links text-lg-right text-center pt-2 pt-lg-0">
            <a href="#">Privacy Policy</a>
            <a href="#">Terms of Use</a>
          </nav>
        </div>
      </div>
    </div>
  </footer><!-- End Footer -->
  <a href="#" class="back-to-top"><i class="ri-arrow-up-s-line"></i></a>
</body>
</html>
