import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http'; 
import {FormsModule} from '@angular/forms';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { HomeComponent } from './pages/home/home.component';
import { SuccessAccountComponent } from './pages/success-account/success-account.component';
import { StripeModule } from "stripe-angular"

@NgModule({
  declarations: [
    AppComponent,
    CreateAccountComponent,
    HomeComponent,
    SuccessAccountComponent
  ],
  imports: [
    StripeModule.forRoot("pk_live_vRu79YBeC6qP4YEwXJK3V2Xt"),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
