import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'

@Component({
  selector: 'app-success-account',
  templateUrl: './success-account.component.html',
  styleUrls: ['./success-account.component.css']
})
export class SuccessAccountComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {

        //use to nav toggler be active
        $('.navbar-toggler').click(function () {
          $('#exampleModal').appendTo("body");
        })
  }
}
